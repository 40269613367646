import axios from 'axios';
import { createApp } from 'vue';
// import { Toast } from 'vant';
import qs from 'qs';

const app = createApp();
// app.use(Toast);
const token = '';

// 创建 axios 实例
const service = axios.create({
  baseURL: 'http://interface.x8game.net', // process.env.BASE_API     api base_url  http://192.168.18.52:8010/xbwl
  timeout: 60000, // 请求超时时间
  transformRequest: obj => qs.stringify(obj),
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

service.interceptors.request.use((config) => { 
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['Access-Token'] = token; // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  return config;
}, (error) => Promise.reject(error));

// 请求拦截器，内部根据返回值，重新组装，统一管理。
service.interceptors.response.use((response) => {
  if (response) {
    if (response.status === 200) {
      return response.data;
    }
    // Toast(response.data.message)
    console.log('response.data.message', response);
    return false;
  }
  // Toast('请求数据失败，请刷新重试！')
  console.log('请求数据失败，请刷新重试！');
  return false;
}, (error) => Promise.reject(error));

service.postJson = (path, params) => service({
  url: path,
  method: 'post',
  data: params,
  transformRequest: obj => JSON.stringify(obj),
  headers: { 'Content-Type': 'application/json' }
})

export default service;
