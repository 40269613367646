<template>
  <div class="foot">
    <div class="text">
      <p>苏州雄霸网络有限公司 网站备案号 <a href="http:\\beian.miit.gov.cn" class="aaa" title="">苏ICP备19004020号</a> <a href="http://www.x8game.com/rule.html" class="aaa" title="">网站公约</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterContent",
  props: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.foot {
  width: 100%;
  // height: 130px;
  height: 80px;
  line-height: 80px;
  background-color: #333;
  color: #fff;

  .ctt-logo {
    float: left;
    width: 222px;
    height: 66px;
    margin: 30px 0 0 30px;
    img {
      display: block;
    }
  }
  .text {
    float: left;
    text-align: center;
    // padding: 20px 0 0 23px;
    // display: inline;
    // width: 820px;
    width: 100%;
    // height: inherit;
    color: #999;
    font-size: 14px;
    p {
      // line-height: inherit;
      margin: 0;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
    a:hover{
      color: #409EFF;
    text-decoration: underline;
    }
  }
}
</style>
