<template>
  <div>
    <header-content></header-content>
  
    <div class="swiper">
      <el-carousel arrow="always" :height="bannerHeight + 'px'">
        <el-carousel-item v-for="(item, index) in swiperlist" :key="index">
          <video
            v-if="item.targetType == 'video'"
            id="vod0"
            :src="item.bannerUrl"
            loop
            autoplay
            preload
            muted
            :style="{ width: bannerWidth + 'px', height: bannerHeight + 'px' }"
          ></video>
          <a v-else :href="item.bannerTargetUrl">
            <img
              class="bannerImg"
              :src="item.bannerUrl"
              ref="image"
              :height="bannerHeight + 'px'"
              :style="{ height: bannerHeight + 'px' }"
            />
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="text-content">
      <div class="menu-index">
        <div class="qr_code">
          <div class="qr_item">
            <img
              src="../assets/img/home_weixin.png"
            />
            <p>关注雄霸网络官方网站</p>
          </div>
        </div>
        <div class="des">
          <router-link to="/">首页</router-link>
          <router-link to="/about">关于我们</router-link>
          <router-link to="/game">产品介绍</router-link>
          <router-link to="/join">加入我们</router-link>
          <router-link to="/contact">联系我们</router-link>
        </div>
      </div>
    </div>
    <footer-content></footer-content>
  </div>
</template>
<script>
import HeaderContent from "@/components/HeaderContent.vue";
import FooterContent from "@/components/FooterContent.vue";
import { isMobile } from '@/utils/util'
export default {
  name: "Home",
  components: {
     HeaderContent,
    FooterContent
  },
  data() {
    return {
      bannerWidth: 0,
      bannerHeight: 960,
      screenWidth: 0,
      screenHeight: 960,
      swiperlist: [
        {
          bannerTargetUrl: "",
          targetType: "video",
          bannerUrl: require("../assets/img/video1.mp4"),
        },
        {
          bannerTargetUrl: "",
          targetType: "video",
          bannerUrl: require("../assets/img/video2.mp4"),
        },
        {
          bannerTargetUrl: "",
          targetType: "img",
          bannerUrl:
            require("../assets/img/home_banner01.jpg"),
        }
      ],
    };
  },
  mounted() {
     if (isMobile()) {
      // alert("手机端");
      this.$router.replace('/homem');
    } else {
      // alert("pc端");
      // this.$router.replace('/');
    }
    // 首次加载时,需要调用一次
    this.screenWidth = $(window).width();
    this.screenHeight = $(window).height();
    this.setSize();
    // 窗口大小发生改变时,调用一次
    window.onresize = () => {
      this.screenWidth = $(window).width();
      this.screenHeight = $(window).height();
      this.setSize();
    };
  },
  methods: {
    //  isMobile() {
    //   let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    //   return flag;
    // },
    setSize() {
      console.log(this.bannerHeight);
      if (this.screenWidth > 1912) {
        this.bannerWidth = this.screenWidth;
        this.bannerHeight = parseInt((900 * this.screenWidth) / 1920);
        console.log("this.screenWidth > 1912");
      } else {
        this.bannerHeight = this.screenHeight;
        this.bannerWidth = parseInt((1920 * this.screenHeight) / 900);
        console.log("屏幕高度就是图片高度");
      }
      console.log(
        "this.screenWidth---->",
        this.screenWidth,
        "this.bannerHeight----->",
        this.bannerHeight
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.el-carousel__item {
  h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
  a {
    display: block;
  }
  video {
    display: block;
  }
}

.el-carousel__item:nth-child(2n) {
  background-color: #fff;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #fff;
}
.bannerImg {
  display: block;
  width: 100%;
  height: inherit;
  object-fit: cover;
}

.text-content {
  position: relative;
  background-color: #fff;
  .menu-index {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    font-size: 15px;
    padding: 100px 0;
    position: relative;
    height: 200px;
    .qr_code {
      box-sizing: border-box;
      border-right: 1px solid #eee;
      width: 350px;
      float: left;
      height: inherit;
      padding-top: 40px;
      .qr_item {
        display: inline-block;
        text-align: center;
        width: 150px;

          img {
            width: 94px;
            vertical-align: middle;
          }
    
        p {
          font-size: 12px;
          color: #494949;
          margin-top: 10px;
        }
      }
    }
    .des {
      float: right;
      right: 0;
      top: 20px;
      width: 850px;
      display: flex;
      justify-content: space-around;
      a {
        line-height: 200px;
        display: inline-block;
        color: #555;
        text-decoration: none;
      }
    }
  }
}
</style>