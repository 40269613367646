import { createRouter, createWebHashHistory } from 'vue-router';
// const Home = ()=>import('@/views/Home.vue')
import Home from '@/views/Home.vue'
const HomeM = ()=>import('@/views/HomeM.vue')
const Contact = ()=>import('@/views/Contact.vue')
const ContactM = ()=>import('@/views/ContactM.vue')
const Join = ()=>import('@/views/Join.vue');
const Login = ()=>import('@/views/Login.vue') 
const LoginM = ()=>import('@/views/LoginM.vue') 
const Mycenter = ()=>import('@/views/Mycenter.vue') 
const MycenterM = ()=>import('@/views/MycenterM.vue') 
const Game = ()=>import('@/views/Game.vue')
const GameM = ()=>import('@/views/GameM.vue')
const AboutM = ()=>import('@/views/AboutM.vue') 
const JoinM = ()=>import('@/views/JoinM.vue') 

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/homem',
    name: 'HomeM',
    component: HomeM,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/loginm',
    name: 'LoginM',
    component: LoginM,
  },
  {
    path: '/mycenter',
    name: 'Mycenter',
    component: Mycenter,
  },
  {
    path: '/mycenterm',
    name: 'MycenterM',
    component: MycenterM,
  },
  {
    path: '/game',
    name: 'Game',
    component: Game,
  },
  {
    path: '/gamem',
    name: 'GameM',
    component: GameM,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/aboutm',
    name: 'AboutM',
    component: AboutM,
  },
  {
    path:'/contact',
    name: 'Contact',
    component:Contact,
  },
  {
    path:'/contactm',
    name: 'ContactM',
    component:ContactM,
  },
  {
    path:'/join',
    name: 'Join',
    component:Join,
  },
  {
    path:'/joinm',
    name: 'JoinM',
    component:JoinM,
  }
  ,{
    path:'/:pathMatch(.*)*',
    redirect:{name:"Home"}
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
