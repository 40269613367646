import { createStore } from 'vuex';

export default createStore({
  state: {
    username:'请登录',
    isLogin:false
  },
  mutations: {
    changeUserType(state,userName){
      state.username = userName;
    },
    changeLoginStatus(state,type){
      state.isLogin=type;
    }
  },
  actions: {
    changeUserType(context){
      context.commit('changeUserType')
    },
    changeLoginStatus(context){
      context.commit('changeLoginStatus')
    }
  },
  modules: {
  },
});
