<template>
  <div :class="isShow">
    <div class="left">
      <img
        :src="logoImg"
        alt=""
        srcset=""
        @click="$router.push({name:'Home'})"
      />
    </div>
    <div class="meddle">
      <router-link to="/">首页</router-link>
      <router-link to="/about">公司介绍</router-link>
      <router-link to="/game">产品介绍</router-link>
      <router-link to="/join">加入我们</router-link>
      <router-link to="/contact">联系我们</router-link>
      <!-- <router-link v-show="isLogin" to="/mycenter">个人中心</router-link> -->
    </div>
    <div class="right">
      <img :src="myImg" class="myimg"/>
      <span @click="goMycenter()">{{ username=='请登录'?'请登录':'个人中心' }}</span>
      <span v-show="isLogin" @click="logOut()">&nbsp;&nbsp; [ 退出 ]</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"; //注册 action 和 state
import axios from "@/utils/axios";
import { ElMessage } from "element-plus";
export default {
  name: "HeaderContent",
  props: {},
  computed: {
    //在这里映射 store.state.userType，使用方法和 computed 里的其他属性一样
    ...mapState(["username","isLogin"]),
  },
  data() {
    return {
      isShow: "header", // 滚动到顶部 背景透明 white-bg
      userInfo: {},
      logoImg:require("../assets/img/logowhite.png"),
      myImg:require("../assets/img/my_white.png"),
      // isLogin:false
    };
  },
  mounted() {
    let userInfoData = window.sessionStorage.getItem("userinfo");
    console.log('userInfoData',userInfoData!=null,)
    if (userInfoData != null) {
      // this.isLogin=true;
      this.userInfo = JSON.parse(window.sessionStorage.getItem("userinfo"));
      this.$store.commit("changeUserType", this.userInfo.username);
      this.$store.commit('changeLoginStatus', true);
    } else {
      // this.isLogin=false;
      console.log('请登录')
      this.$store.commit("changeUserType", "请登录");
      this.$store.commit('changeLoginStatus', false);
    }
    window.addEventListener("scroll", this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
  },
  methods: {
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量
      // console.log('距离顶部的距离',scrollTop)
      if(scrollTop === 0){
        this.isShow ="header";
        this.logoImg=require("../assets/img/logowhite.png");
        this.myImg=require("../assets/img/my_white.png");
      }else{
        this.isShow ="header white-bg";
        this.logoImg=require("../assets/img/top_logo.png");
        this.myImg=require("../assets/img/my_black.png");
      }
    },
    goMycenter() {
      if(this.isLogin){
        this.$router.push({ name: "Mycenter" });
      }else{
        this.$router.push({ name: "Login" });
      }
    },
    logOut() {
      let token = window.sessionStorage.getItem("token");
      this.userInfo = JSON.parse(window.sessionStorage.getItem("userinfo"));
      axios
        .post("/logout", { token: token, username: this.userInfo.username })
        .then((res) => {
          if (res.code == 1) {
            window.sessionStorage.removeItem('userinfo');
            window.sessionStorage.removeItem('token');
            this.$store.commit("changeUserType", "请登录");
            this.$store.commit('changeLoginStatus', false);
            this.$router.push({ name: "Home" });
            ElMessage({
              message: "退出登录成功",
              type: "success",
            });
          } else if (res.code == 0) {
            ElMessage({
              message: res.msg,
              type: "error",
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  z-index: 8;
  font-size: 14px;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  overflow: hidden;

  .left,
  .right {
    width: 20%;
    float: left;

  }
  .meddle {
    float: left;
    width: 60%;
    box-sizing: border-box;
    padding: 0 50px;
    display: flex;
    justify-content: space-around;
  }
  a{
    color: inherit;
    display: inline-block;
    font-size: 16px;
    line-height: 80px;
    text-decoration: none;
  }
  .left {
    img {
      margin-top: 16px;
      cursor: pointer;
    }
  }
  .right {
    font-size: 16px;
    line-height: 80px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    span,img{
      display: block;
    }
    .myimg{
      width: 18px;
      height: 18px;
      margin-right: 2px;
      margin-top: 30px;
    }
  }
}
.white-bg {
  background-color: #fff;
  border-bottom: 1px solid #eee;
  div {
    a,
    & > span {
      color: #333;
    }
  }
}
</style>